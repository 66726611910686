//
//
//
//
//
//
//

import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState(['lang'])
  },
  methods: {
    click(lang) {
      this.$store.commit('lang', lang);
    }
  }
};
