//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
export default {
  async fetch() {
    this.about = await this.$content('about').fetch();
  },
  data() {
    return {
      about: {}
    };
  },
  computed: {
    ...mapState(['lang'])
  },
  mounted() {
    this.attachEvents();
  },
  methods: {
    resize() {
      if (this.$device.isMobile || this.lang != 'en') return;

      this.$el.style.height = null;
      setTimeout(() => {
        this.$el.style.height = `${this.$el.getBoundingClientRect().height}px`;
      }, 10);
    },
    enter(el, done) {
      const paragraphs = this.$device.isMobile ? [...document.querySelectorAll('.about p, .contact')] : [...el.querySelectorAll('p')];
      this.$gsap.fromTo(
        paragraphs,
        {opacity: 0, y: 4},
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          delay: T_FAST * 0.9,
          duration: T_MEDIUM,
          ease: 'out',
          onComplete: () => {
            window.dispatchEvent(new Event('resize'));
            done();
          }
        }
      );
    },

    leave(el, done) {
      this.$gsap.to(el, {position: 'absolute', opacity: 0, y: -4, duration: T_FAST, ease: 'in', onComplete: done});
    },
    attachEvents() {
      this.resize();
      window.addEventListener('resize', this.resize);
    }
  }
};
