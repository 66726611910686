//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.attachEvents();
  },
  beforeDestroy() {
    this.detachEvents();
  },
  methods: {
    attachEvents() {},
    detachEvents() {}
  }
};
