//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      video: null,
      showUnmute: true
    };
  },
  beforeMount() {
    this.video = (parseInt(localStorage.getItem('sgyVideo') ?? '0') + 1) % 5;
    localStorage.setItem('sgyVideo', this.video);
  },
  computed: {
    showInfo() {
      return !this.$device.isMobile;
    }
  },

  mounted() {
    this.attachEvents();
  },
  beforeDestroy() {
    this.detachEvents();
  },
  methods: {
    raf() {
      this.rafID = requestAnimationFrame(this.raf);
      const scale = 1 + (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 0.1;
      const transformY = window.scrollY * 0.2;
      this.$el.style.transform = `translate3d(0,${transformY}px,0)`;
      this.$refs.video.style.transform = `scale(${scale})`;
    },
    toInformation() {
      const from = window.scrollY;
      const {top: informationTop, height: informationHeight} = document.querySelector('.information').getBoundingClientRect();
      const to = informationHeight < window.innerHeight ? document.body.scrollHeight - window.innerHeight : informationTop + window.scrollY;

      this.$gsap.fromTo(window, {scrollTo: from}, {scrollTo: to, duration: T_SLOW, ease: 'inOut'});
    },
    videoUnmute() {
      this.$refs.video.muted = false;
      this.showUnmute = false;
    },

    /*
     * Events
     */
    resize() {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    },
    attachEvents() {
      this.raf();
      this.resize();
      window.addEventListener('resize', this.resize);
      window.addEventListener('orientationchange', this.resize);
    },
    detachEvents() {
      cancelAnimationFrame(this.rafID);
      window.removeEventListener('resize', this.resize);
      window.removeEventListener('orientationchange', this.resize);
    }
  }
};
