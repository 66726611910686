import { render, staticRenderFns } from "./index.vue?vue&type=template&id=24b1c8e6&"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Teaser: require('/vercel/path0/components/Teaser.vue').default,Information: require('/vercel/path0/components/Information.vue').default})
