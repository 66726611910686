//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    showLanguages() {
      return this.$device.isMobile;
    }
  }
};
